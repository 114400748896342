<template>
  <v-row class="bbr-import-recipe">
    <v-col cols="12">
      <h3>Import Recipes</h3>
    </v-col>
    <v-col cols="6">
      <v-form v-model="valid">
        <v-container>
          <v-file-input
            v-model="form.file"
            accept=".xls,.xlsx"
            label="Import recipes"
          >
          </v-file-input>

          <v-row no-gutters class="mb-6">
            <v-col>
              <v-checkbox
                class="mr-10 align-self-stretch"
                v-model="form.reset_ingredients"
                label="Reset Ingredients"
                hide-details
              />
            </v-col>
            <v-col>
              <v-checkbox
                class="mr-10 align-self-stretch"
                v-model="form.reset_booster_directions"
                label="Reset food boosters & directions"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-checkbox
                class="mr-10 pb-5 align-self-stretch"
                v-model="form.category_column"
                label="Use Category Column in Recipe template"
                hide-details
              />
            </v-col>
          </v-row>

          <v-card class="mx-auto py-3 px-1" outlined>
            <v-card-title class="title">Category</v-card-title>
            <v-card-text>
              <v-row no-gutters v-for="row in categories" :key="row.id">
                <v-col>
                  <v-checkbox
                    class="mr-10 align-self-stretch"
                    v-model="form.categories"
                    :label="row.name"
                    :value="row.id"
                    hide-details
                  />
                </v-col>
                <v-col class="pt-5">
                  <span
                    >Booster: <b>{{ row.is_booster }}</b> Active:
                    <b>{{ row.is_active }}</b></span
                  >
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-container>
        <v-btn class="mr-4" @click="importFile" :loading="loading">
          Submit
        </v-btn>
      </v-form>
    </v-col>
    <v-toast ref="toast" />
  </v-row>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { values, flattenDeep } from 'lodash'
import VToast from '@/components/elements/Toast'

export default {
  name: 'ImportRecipe',

  components: {
    VToast,
  },

  data() {
    return {
      loading: false,

      valid: null,

      form: {
        file: null,
        categories: [],
        category_column: false,
        reset_ingredients: false,
        reset_booster_directions: false,
      },
    }
  },

  created() {
    this.getFoodCategory({ search: null })
  },

  computed: {
    ...mapState({
      categories: (state) => state.foodCategory.list,
    }),
  },

  methods: {
    ...mapActions({
      getFoodCategory: 'foodCategory/getDataList',
      bulkImport: 'recipe/bulkImport',
    }),

    importFile() {
      if (this.form.file === null) return

      let payload = new FormData()

      payload.append('excelFile', this.form.file)

      payload.append('reset_ingredients', this.form.reset_ingredients)
      payload.append(
        'reset_booster_directions',
        this.form.reset_booster_directions
      )
      payload.append('category_column', this.form.category_column)

      for (var i = 0; i < this.form.categories.length; i++) {
        payload.append('categories[]', this.form.categories[parseInt(i)])
      }

      // Create record
      this.loading = true
      this.bulkImport(payload)
        .then((response) => {
          this.loading = false

          this.form.file = null
          this.form.category_column = false
          this.form.categories = []
          this.form.reset_ingredients = false
          this.form.reset_booster_directions = false

          this.$refs.toast.open({
            color: 'primary',
            message: 'Exporting recipes started!',
          })
        })
        .catch((err) => {
          this.loading = false
          if (err) {
            if (err.response.data !== undefined) {
              this.$refs.toast.open({
                color: 'red',
                timeout: 3000,
                message: flattenDeep(values(err.response.data.errors)).join(
                  ' '
                ),
              })
            }
          }
        })
    },
  },
}
</script>
